import { AxiosResponse } from 'axios'
import { API, SERVICES } from 'common/api'
import { AuthUser } from 'common/types'

const isValidResponse = (response: AxiosResponse<AuthUser>) => {
  if (
    !response ||
    !response.data ||
    !response.data.permissions ||
    !response.data.roles
  )
    return false

  return true
}

export const fetchUserDetails = async (): Promise<AuthUser | null> => {
  try {
    // Okta error paths should land on the error handling page
    if (window.location.pathname.includes('/error')) {
      return null
    }
    const response = await API().get<AuthUser>(
      `${SERVICES.AUTH_SERVICE}/user-details`,
    )

    if (!isValidResponse(response)) {
      console.log('Invalid user details received from Auth Service')
      throw new Error('Invalid user details received from mmp-auth-service')
    }

    return response.data
  } catch (error) {
    console.log('Error occurred while retrieving user details')
    throw new Error('Error occurred while retrieving user details: ' + error)
  }
}
