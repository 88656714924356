import {
  MutationCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import React from 'react'

const queryClient = new QueryClient({
  mutationCache: new MutationCache(),
  defaultOptions: {
    mutations: {
      useErrorBoundary: false,
    },
    queries: {
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
      retry: false,
      staleTime: Infinity,
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
})

type ReactQueryProviderProps = { children: JSX.Element }

export const ReactQueryProvider: React.FC<ReactQueryProviderProps> = ({
  children,
}) => <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
